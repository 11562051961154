<template>
  <div>
    <Pane :bottom="0"
          :showRemarks="false">
      <a-tabs v-model="activeKey"
              :tabBarStyle="{
          margin: '0',
        }">
        <a-tab-pane v-for="item in tabList"
                    :key="item.key"
                    :tab="item.name"
                    :disabled="item.disabled"></a-tab-pane>
      </a-tabs>

      <template v-slot:extra>
        <div class="export-download">
          <div @click="visible=true"
               class="progress"
               v-if="!processDisable">
            <img src="@/assets/epc/progress.jpg"
                 alt="">
            <a>流程查看</a>
          </div>
        </div>
      </template>
    </Pane>
    <a-modal :visible="visible"
             title="流程图"
             cancelText="关闭"
             :footer="null"
             @cancel="visible=false">

      <img :src="imgUrl"
           style="width:100%">
    </a-modal>

    <div class="container">
      <CommissionPlan v-show="activeKey === '0' && pData.projectName"
                      :msg="pData"></CommissionPlan>
      <CommissionInspection v-show="activeKey === '1' && pData.projectName"
                            :msg="pData"></CommissionInspection>
    </div>
  </div>
</template>

<script>
import CommissionPlan from "./components/commission-plan";
import CommissionInspection from "./components/commission-inspection";

import { fetchDetail } from "@/api/epc";

export default {
  name: "completeEnd",
  components: {
    CommissionPlan,
    CommissionInspection,
  },
  data() {
    return {
      imgUrl: require("@/assets/epc/approvalFlow/6-1end.png"),

      visible: false,
      processDisable: false,
      activeKey: "0",
      tabList: [
        { key: "0", name: "试运行方案", disabled: false },
        { key: "1", name: "试运行检查表", disabled: false },
      ],

      form: this.$form.createForm(this),
      pid: "", // 项目id
      pData: {},

      isView: false,
    };
  },
  watch: {
    activeKey(newV) {
      if (newV === "0") {
        this.processDisable = false;
      } else {
        this.processDisable = true;
      }
    },
  },
  mounted() {
    const { query } = this.$route;
    const { activeKey, pid, id, isView } = query || {}; // 通过activeKey判断当前是哪个tab，id判断是否是编辑页面，isView判断是否是查看页面，

    if (!pid) {
      return;
    }
    this.activeKey = activeKey || "0";
    this.pid = pid;
    this.isView = Boolean(isView) || false;

    if (id) {
      this.setTab();
    }
    this.getProjectInfo();
  },
  methods: {
    setTab() {
      // 从编辑进入页面，设置非活跃状态的tab不可用
      this.tabList.forEach((item) => {
        if (item.key !== this.activeKey) {
          item.disabled = true;
        }
      });
    },
    getProjectInfo() {
      fetchDetail(this.pid).then((res) => {
        this.pData = {
          pid: this.pid,
          designCode: res.designCode,
          projectName: res.name,
        };
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 20px;
}
</style>
