import request from '@/api/request'

// 收尾阶段
export function add(data) {
  return request({
      url: '/market-service/epc/closing/add',
      method: 'post',
      data
  })
}

export function edit(data) {
  return request({
      url: '/market-service/epc/closing/update',
      method: 'post',
      data,
  })
}

export function fetchDetail(params) {
  return request({
      url: `/market-service/epc/closing/query/${params.id}`,
  })
}


